
import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { Publication, PublicationType } from "@/store/modules/publications.store";

@Component
export default class PageSellerPartActivityDetail extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("publications/all") publications!: Publication[];

    get canShare() {
        return navigator.share !== undefined;
    }

    get websitePublication() {
        return this.publications.find((p) => p.type === PublicationType.website);
    }

    async share() {
        if (!this.websitePublication) {
            return;
        }

        const shareData = {
            title: this.activity.name,
            text: this.activity.name,
            url: this.websitePublication.url,
        };

        navigator.share(shareData);
    }
}
